import './App.css'
import greenline from './img/greenline.png'
import pinkline from './img/stripepink.png'
import purpleline from './img/stripepurple.png'
import wgrad from './img/w-grad.jpeg'
import { Text, Button, Grid, Card, Row, Col } from "@nextui-org/react";
import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';

import web from './img/webicon.png'
import commerce from './img/commerce.png'
import mvp from './img/mvp.png'
import saas from './img/saas.png'
import consulting from './img/consulting.png'
import mobile from './img/mobile.png'



function Features() {

    useEffect(() => {
        ScrollReveal().reveal('.headline', { 
          // you can set your own animation parameters here
          delay: 500, 
          distance: '50px', 
          easing: 'cubic-bezier(.5, 0, 0, 1)', 
          interval: 300 
        });
      }, []); 
    

    return (
        <div>
            {/* ---- 1 -------*/}
            <div id="fea_agency" style={{ paddingTop: '150px',backgroundColor: 'black', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{ borderRadius: '100%', padding: '4px 15px', border: '4px solid #92d5a4'}}>
                <p style={{ fontWeight: '800', color: '#92d5a4'}}>1</p>
            </div>
            <div>
            <Text
                h1
                size={70}
                css={{
                    textGradient: "35deg, #92D5A4 90%, #882CFE 90%",
                }}
                 weight="bold"
            >
            Agency
            </Text>
            </div>
            <div >
               <img style={{ height: '25vh', marginLeft: '5px'}} src={greenline} />
            </div>
            <div>
            <div >
                <Text id="padding_responsive" h3 size={50} css={{ textGradient: "35deg, #92D5A4 100%, #882CFE 90%"}} weight="bold">
                    Everything your business needs online
                </Text>
                <Text h3 css={{ color: '#cccccc', padding: '0% 10%' }}>
                    We analyze your business and develop together the tools that it needs 
                </Text>
            </div>
            <div id="aboutus_valores" style={{ padding: '0% 11%' }}>
                    <Grid.Container gap={2} justify="center" style={{ display: 'flex' }}>
                        <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ background: "#121212"}}>
                                <Card.Header>
                                    <img src={web} width="34px" height="34px" />
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>Web solutions</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Empower your organization with tailor-made enterprise web apps that streamline operations, enhance productivity, and facilitate seamless collaboration</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                             <Card css={{ p: "$6", mw: "400px" }} style={{ background: "#121212"}}>
                                <Card.Header>
                                    <img src={commerce} width="34px" height="34px" />
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>E-commerce</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>From intuitive product catalogs to secure payment gateways, we create e-commerce solutions that drive conversions and elevate your online business</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ background: "#121212"}}>
                                <Card.Header>
                                    <img src={mvp} width="34px" height="34px" />
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>MVP Development</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>If you can imagine it, we can build it. Our team thrives on challenges, crafting unique and innovative web apps that push boundaries</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Grid.Container>
                    <Grid.Container gap={2} justify="center" style={{ display: 'flex' }}>
                        <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ background: "#121212"}}>
                                <Card.Header>
                                    <img src={saas} width="34px" height="34px" />
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>Web Apps</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Streamline your business operations with our custom solutions. We focus on automating processes to reduce costs and enhance agility, ensuring an efficient workflow tailored to your needs.</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                             <Card css={{ p: "$6", mw: "400px" }} style={{ background: "#121212"}}>
                                <Card.Header>
                                    <img src={consulting} width="34px" height="34px" />
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>IT Consulting</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Assess your business needs and develop a detailed strategy and action plan tailored to address them effectively</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ background: "#121212"}}>
                                <Card.Header>
                                    <img src={mvp} width="34px" height="34px" />
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>MVP Development</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Transform your idea into reality. Craft your MVP, validate its concept, and unlock access to funding and fresh business opportunities</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Grid.Container>
                    </div>
            </div>
            <div style={{ padding: '100px 10%'}}>
            <Text h3 size={30} css={{ textGradient: "35deg, #92D5A4 100%, #882CFE 90%"}} weight="bold">
                    Do you want to grow your business online?
                </Text>
                <Text h3 css={{ color: '#cccccc' }}>
                    Read about out process, services and get free insight
                </Text>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <a href="https://www.erimi.agency/agency">
                    <Button style={{ backgroundColor: '#92D5A4', color: '#000000', fontWeight: '800'}}>
                        <p style={{ color: '#000', fontWeight: '600' }}>Read more</p> 
                    </Button>
                    </a>
                </div>
            </div>
            </div>
                        {/* ---- 2 -------*/}
                        <div style={{ paddingTop: '50px', backgroundColor: 'black', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ borderRadius: '100%', padding: '4px 15px', border: '4px solid #882CFE' }}>
                    <p style={{ fontWeight: '800', color: '#882CFE' }}>2</p>
                </div>
                <div>
                    <Text
                        h1
                        size={70}
                        css={{
                            textGradient: "35deg, #882CFE 90%, #882CFE 90%",
                        }}
                        weight="bold"
                    >
                        Web3
                    </Text>
                </div>
                <div >
                    <img style={{ height: '25vh', marginLeft: '5px' }} src={purpleline} />
                </div>
                <div>
                    <div style={{ padding: '0% 5%' }}>
                        <Text className='headline' h3 size={50} css={{ textGradient: "35deg, #882CFE 100%, #882CFE 90%" }} weight="bold">
                            We build Web3 solutions for your business.
                        </Text>
                        <Text className='headline' h3 css={{ color: '#cccccc' }}>
                            Together, we can position your business in Web3 and open new markets.
                        </Text>
                    </div>

                    <Grid.Container gap={2} justify="center">
                        <Grid xs={12} md={6}>
                            <div id="con_block" style={{ padding: '0px 30px', display: 'flex', justifyContent: 'center' }}>
                                <Card id="card_block" css={{ marginTop: '60px', backgroundColor: 'black', border: '1px solid #882CFE', padding: '20px 40px' }}>
                                    <Card.Divider />
                                    <Card.Body css={{ py: "$10" }}>
                                        <Text h3 size={30} justify="center" css={{ textGradient: "35deg, #882CFE 100%, #882CFE 90%" }} weight="bold">
                                        Immerse yourself in Web3
                                        </Text>
                                        <Text h3 css={{ color: '#cccccc' }}>
                                            Our team of experts will guide you every step of the way. We will develop customized Web3 solutions with testing phases and adjustments. Just get ready for the big launch and attract customers from all around the world.
                                            <br /><br />
                                            With constant monitoring and continuous improvement, you will gain security, transparency, and open doors to new global markets.
                                        </Text>
                                    </Card.Body>
                                    <Card.Divider />
                                </Card>
                            </div>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <div style={{ textAlign: 'left' }}>
                                <Text h3 size={30} css={{ padding: '0% 10%', marginTop: '80px', textGradient: "35deg, #ebe8e8 100%, #882CFE 90%" }} weight="bold">
                                    DApps Development
                                </Text>
                                <Text p css={{ padding: '0% 10%', color: '#cccccc', fontWeight: '600' }}>
                                Development of applications operating on a decentralized network, providing secure and transparent experiences.
                                </Text>

                                <Text h3 size={30} css={{ padding: '0% 10%', marginTop: '40px', textGradient: "35deg, #ebe8e8 100%, #882CFE 90%" }} weight="bold">
                                    Smart Contracts
                                </Text>
                                <Text p css={{ padding: '0% 10%', color: '#cccccc', fontWeight: '600' }}>
                                Ensures the transparent execution of agreements and transactions without the need for intermediaries.
                                </Text>

                                <div id="moreinfo" className='headline' style={{ background: '#882CFE', padding: '0px 50px', borderRadius: '15px' }}>
                                    <Text h3 size={30} css={{ marginTop: '40px', textGradient: "35deg, #ebe8e8 100%, #882CFE 90%", padding: '20px 40px', textAlign: 'center' }} weight="bold">
                                        Get more information on how we can help you achieve your goals.
                                    </Text>
                                    <Text p css={{ color: '#cccccc', fontWeight: '600', padding: '0px 40px 30px 40px', textAlign: 'center' }}>
                                    Our customer-centric process ensures your involvement and control at all times as we build your Web3 solutions.
                                    </Text>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
                                    <a href="https://www.erimi.agency/web3">
                                        <Button style={{ backgroundColor: '#ffffff', color: '#882CFE', fontWeight: '800' }}>
                                                <p style={{ color: '#000', fontWeight: '600' }}>Read more</p>
                                        </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid.Container>
                </div>
            </div>


                        {/* ---- 3 -------*/}
                        <div style={{ paddingTop: '150px',backgroundColor: 'black', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{ borderRadius: '100%', padding: '4px 15px', border: '4px solid #FCA6BF'}}>
                <p style={{ fontWeight: '800', color: '#FCA6BF'}}>3</p>
            </div>
            <div>
            <Text
                h1
                size={70}
                css={{
                    textGradient: "35deg, #FCA6BF 90%, #882CFE 90%",
                }}
                 weight="bold"
            >
            Products
            </Text>
            </div>
            <div >
               <img style={{ height: '25vh', marginLeft: '5px'}} src={pinkline} />
            </div>
            <div>
            <div >
                <Text h3 size={50} css={{ textGradient: "35deg, #FCA6BF 100%, #882CFE 90%"}} weight="bold">
                    Discover our products
                </Text>
                <Text id="padding_responsive" h3 css={{ color: '#cccccc' }}>
                    We develop amazing products to improve productivity and processes.
                </Text>
            </div>
            </div>

            <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
            <Card id="card_w_fea" css={{ w: "50%", h: "400px" }}>
                        <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
                            <Col>
                            </Col>
                        </Card.Header>
                        <Card.Body css={{ p: 0 }}>
                            <Card.Image
                                src={wgrad}
                                width="100%"
                                height="100%"
                                objectFit="cover"
                                alt="Ejemplo de fondo de tarjeta"
                            />
                        </Card.Body>
                        <Card.Footer
                            isBlurred
                            css={{
                                position: "absolute",
                                bgBlur: "#ffffff99",
                                borderTop: "$borderWeights$light solid rgba(255, 255, 255, 0.2)",
                                bottom: 0,
                                zIndex: 1,
                            }}
                        >
                            <Row>
                                <Col style={{ textAlign: 'left' }}>
                                    <Text color="#000" size={12}>
                                        WRKSYNC
                                    </Text>
                                    <Text color="#000" size={12}>
                                        Your new work companion
                                    </Text>
                                </Col>
                                <Col>
                                    <Row justify="flex-end">
                                    <a href="https://www.erimi.agency/wrksync">
                                        <Button flat auto rounded color="secondary">
                                            <Text
                                                css={{ color: "inherit" }}
                                                size={12}
                                                weight="bold"
                                                transform="uppercase"
                                            >
                                                <a href="https://www.erimi.agency/wrksync">
                                                    <p style={{ color: "#882CFE", fontWeight: '600'}}>Waitlist</p>
                                                </a>
                                            </Text>
                                        </Button>
                                        </a>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
            </div>

            </div>
        </div>
    )
}

export default Features
