
import { Text, Button, Grid, Card, Row, Col, Link } from "@nextui-org/react";

import Nav from './Nav/Nav'
import Footer from './Footer'

import stripe from './img/stripepink.png'
import logo from './img/logo.png'
import stripepurple from './img/stripepurple.png'
import i_create from './img/icreate.png'
import i_remote from './img/iremote.png'
import i_innovation from './img/iinnovate.png'
import i_perfect from './img/iperfect.png'




function AboutUs() {

    return(
        <div>
            <Nav />
            <div style={{ background: '#000', minHeight: '90vh', paddingTop: '80px', paddingBottom: '10%'}}>
            <Grid.Container style={{ padding: '0% 10%' }} gap={2} justify="center">
                    <Grid xs={12} md={8}>
                        <div id="agency-card" style={{ padding: '0px 30px',  justifyContent: 'center' }}>
                            <Text h3 size={50} css={{ textGradient: "35deg, #FCA6BF 100%, #882CFE 90%" }} weight="bold">
                                The best team to get the best results
                            </Text>
                            <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                            In Erimi, we have a dynamic and passionate team that does things differently. Our philosophy is based on innovation and creativity, always seeking unique solutions for each challenge. We take pride in being pioneers in our industry and making a difference in every project we undertake.
                            </Text>
                            <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                            Our process revolves around the client at all times. We attentively listen to their needs and objectives, working in close collaboration to develop tailored solutions that drive their success. We ensure that every step of the way is focused on customer satisfaction, providing exceptional service and results that exceed all expectations. With Erimi, the success of our clients is our success, and together we build a path towards a brighter future.
                            </Text>
                        </div>
                    </Grid>
                    <Grid xs={0} md={4}>
                        <img style={{ width: '300px', height: '300px'}} src={logo} />
                    </Grid>
                </Grid.Container>
                <div style={{ background: '#882CFE', margin: '5%', borderRadius: '30px'}}>
                <div id="agency-card" style={{ }}>
                           <div id="aboutus-adjust" style={{ padding: '5%', textAlign: 'center'}}>
                           <Text h3 size={40} css={{ textGradient: "35deg, #f2f2f2, 100%, #882CFE 90%" }} weight="bold">
                                Scalable and adaptable team for all projects.
                            </Text>
                            <Text h3 css={{ color: '#cccccc' }}>
                                We are based on an adaptable business model that allows us to employ only the necessary personnel to develop your idea; thus, your budget will only include the necessary expenses for your project.
                            </Text>
                           </div>
                        </div>
                </div>
                <Grid.Container style={{ padding: '10% 10%' }} gap={2} justify="center">
                    <Grid xs={12} md={8}>
                        <div id="agency-card" style={{ padding: '0px 30px',  justifyContent: 'center' }}>
                            <Text h3 size={50} css={{ textGradient: "35deg, #882CFE 100%, #882CFE 90%" }} weight="bold">
                                A clear process focused on the customer.
                            </Text>
                            <Text p css={{ color: '#cccccc',  fontSize: '19px' }}>
                                At Erimi, we put all our focus on you. Our process starts with a thorough analysis of your business and environment to understand your needs and challenges. From there, we present the best solutions tailored to your goals, and we are here to address all your doubts at every step of the way.
                            </Text>
                            <Text p css={{ color: '#cccccc',  fontSize: '19px' }}>
                            We believe in collaboration as a fundamental pillar. We will keep you informed about the project's status regularly and transparently, so you can fully grasp the progress, regardless of your specific technology knowledge. At Erimi, we work hand in hand with you to ensure that the final result reflects your goals and expectations, creating a path to success together.
                            </Text>
                        </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <div style={{ marginTop: '50px', width: '80%', marginLeft: '10%'}}>
                            <div style={{ display: 'flex'}}>
                                <div style={{ border: '2px solid #882CFE', padding: '1px 12px', color: '#fff', borderRadius: '100%'}}><p style={{ fontWeight: '800' }}>1</p></div>
                                <div style={{ color: '#fff' }}><p style={{ fontSize: '25px', marginTop: '-5px', fontWeight: '800', position: 'absolute', marginLeft: '10px'}}>Analysis</p></div>
                            </div>
                            <img style={{ marginLeft: '16px', height: '100px'}} src={stripepurple} />
                            <div style={{ display: 'flex'}}>
                                <div style={{ border: '2px solid #882CFE', padding: '1px 12px', color: '#fff', borderRadius: '100%'}}><p style={{ fontWeight: '800' }}>2</p></div>
                                <div style={{ color: '#fff' }}><p style={{ fontSize: '25px', marginTop: '-5px', fontWeight: '800', position: 'absolute', marginLeft: '10px'}}>Plan</p></div>
                            </div>
                            <img style={{ marginLeft: '16px', height: '100px'}} src={stripepurple} />
                            <div style={{ display: 'flex'}}>
                                <div style={{ border: '2px solid #882CFE', padding: '1px 12px', color: '#fff', borderRadius: '100%'}}><p style={{ fontWeight: '800' }}>3</p></div>
                                <div style={{ color: '#fff' }}><p style={{ fontSize: '25px', marginTop: '-5px', fontWeight: '800', position: 'absolute', marginLeft: '10px'}}>Development</p></div>
                            </div>
                        </div>
                    </Grid>
                </Grid.Container>
                <div id="aboutus_valores" style={{ padding: '0% 11%' }}>
                    <Text h3 size={30} css={{ textGradient: "35deg, #882CFE, 100%, #882CFE 90%", marginLeft: '10px' }} weight="bold">
                        Our values
                    </Text>
                <Grid.Container gap={2} justify="center" style={{ display: 'flex' }}>
                    <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                        <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #882CFE', background: "black"}}>
                            <Card.Header>
                                <img src={i_create} width="34px" height="34px" />
                                <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>Creativity</Text>
                            </Card.Header>
                            <Card.Body css={{ py: "$2" }}>
                                <Text style={{ color: '#ccc' }}>We reinvent solutions to adapt them to you in the most creative and intelligent way</Text>
                            </Card.Body>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                         <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #882CFE', background: "black"}}>
                            <Card.Header>
                                <img src={i_innovation} width="34px" height="34px" />
                                <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>Innovation</Text>
                            </Card.Header>
                            <Card.Body css={{ py: "$2" }}>
                                <Text style={{ color: '#ccc' }}>We use the latest technologies to provide you with the most advanced tools</Text>
                            </Card.Body>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                        <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #882CFE', background: "black"}}>
                            <Card.Header>
                                <img src={i_remote} width="34px" height="34px" />
                                <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px'}}>Remote</Text>
                            </Card.Header>
                            <Card.Body css={{ py: "$2" }}>
                                <Text style={{ color: '#ccc' }}>
We believe in work-life balance and only work with the best, no matter where they are</Text>
                            </Card.Body>
                        </Card>
                    </Grid>
                </Grid.Container>
                </div>
        </div>
        <Footer />
        </div>
    )
}

export default AboutUs