import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { NextUIProvider } from '@nextui-org/react';
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Wrksync from './WrkSync'
import Home from './Home'
import Contact from './Contact'
import AboutUs from './AboutUs'
import Agency from './Agency'
import Web3 from './Web3'
import Products from './Products'
import Privacy from './Privacy'
import NotFound from "./NotFound";



const rootElement = document.getElementById("root");

const router = createBrowserRouter([
  {
    path: "/wrksync",
    element: <Wrksync />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/about",
    element: <AboutUs />,
  },
  {
    path: "/agency",
    element: <Agency />,
  },
  {
    path: "/web3",
    element: <Web3 />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

ReactDOM.render(
  <StrictMode>
    <NextUIProvider>
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </NextUIProvider>
  </StrictMode>,
  rootElement
);
