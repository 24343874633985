
import './App.css';


import Nav from './Nav/Nav'
import Hero from './Hero/Hero'
import Features from './Features'
import About from './About'
import Footer from './Footer'


import { Helmet } from 'react-helmet';




function Home() {

  return (
      <div className="Home">
          <Helmet>
              <title>Erimi Agency</title>
              <meta property="og:title" content="Erimi Agency" />
              <meta property="og:description" content="Specializing in Web3 and blockchain solutions, we're a premier development agency that turns visions into market-ready products. Whether it's software and MVP development or being your dedicated Shopify agency, we lead the tech revolution with expertise and innovation." />
              <meta property="og:url" content="https://erimi.agency/" />
              <meta property="og:image" content="https://mysite.com/path/to/image.jpg" />
          </Helmet>
          
 
              <>
                  <Nav />
                  <Hero />
                  <Features />
                  <About />
                  <Footer />
              </>
          
      </div>
  );
}

export default Home