import { Text, Grid, Card, Button, Col, Row } from "@nextui-org/react";
import Nav from './Nav/Nav';
import Footer from './Footer';

import wgrad from './img/w-grad.jpeg'

function Agency() {
    return (
        <div>
            <Nav />
            <div style={{ background: '#000', minHeight: '90vh', paddingTop: '80px', padding: '10% 15%' }}>
                        <div id="agency-card" style={{  justifyContent: 'center' }}>
                            <Text id="padding_responsive" h3 size={50} css={{ textGradient: "35deg, #FCA6BF 100%, #FCA6BF 90%" }} weight="bold">
                                Products by Erimi
                            </Text>
                            <Text id="padding_responsive" p css={{ color: '#cccccc', fontSize: '19px' }}>
                            Innovation and creativity are values we all share at Erimi, which is why it's inevitable that one of our greatest passions is to design and create new products. We present to you the most incredible products we have developed so far.
                            </Text>
                        </div>
                        <Card id="card_w_fea" css={{ w: "50%", h: "400px" }} style={{ marginTop: '60px'}}>
                            <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
                                <Col>
                                </Col>
                            </Card.Header>
                            <Card.Body css={{ p: 0 }}>
                                <Card.Image
                                    src={wgrad}
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                    alt="Card example background"
                                />
                            </Card.Body>
                        <Card.Footer isBlurred css={{ position: "absolute",bgBlur: "#ffffff99",borderTop: "$borderWeights$light solid rgba(255, 255, 255, 0.2)",bottom: 0,zIndex: 1,}} >
                            <Row>
                                <Col style={{ textAlign: 'left' }}>
                                    <Text color="#000" size={12}>
                                        WRKSYNC
                                    </Text>
                                    <Text color="#000" size={12}>
                                        The new way of working
                                    </Text>
                                </Col>
                                <Col>
                                    <Row justify="flex-end">
                                    <a target="_blank" href="https://www.erimi.agency/wrksync">
                                        <Button flat auto rounded color="secondary">
                                            <Text
                                                css={{ color: "inherit" }}
                                                size={12}
                                                weight="bold"
                                                transform="uppercase"
                                            >
                                            Waitlist
                                            </Text>
                                        </Button>
                                        </a>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
            </div>
                        
            <Footer />
        </div>
    );
}

export default Agency;