import { Navbar, Button, Link, Text, useTheme } from "@nextui-org/react";
import { Layout } from "./Layout.js";
import logo from '../img/logo.png'


export default function App() {

  return (
    <Layout style={{ background: '#000000', fontFamily: 'Nunito, sans-serif' }}>
      <Navbar variant="sticky" >
      <a href="https://www.erimi.agency/">
        <Navbar.Brand>
        <img style={{ width: '50px'}} src={logo} />
        </Navbar.Brand>
        </a>
        <Navbar.Content enableCursorHighlight hideIn="xs" variant="underline-rou">
          <Navbar.Link style={{ color: '#ccc'}} id="link" href="https://www.erimi.agency/">Home</Navbar.Link>
          <Navbar.Link style={{ color: '#ccc'}} id="link" href="https://www.erimi.agency/about">
            About us
          </Navbar.Link>
          <Navbar.Link style={{ color: '#ccc'}} id="link" href="https://www.erimi.agency/agency">Agency</Navbar.Link>
          <Navbar.Link style={{ color: '#ccc'}} id="link" href="https://www.erimi.agency/web3">Web3</Navbar.Link>
          <Navbar.Link style={{ color: '#ccc'}} id="link" href="https://www.erimi.agency/products">Products</Navbar.Link>
        </Navbar.Content>
        <Navbar.Content>
          <Navbar.Item>
            <Button auto flat as={Link} href="https://www.erimi.agency/contact" style={{ backgroundColor: '#882CFE', color: '#cccccc', fontWeight: '800', fontFamily: 'Nunito, sans-serif' }}>
            <a href="https://www.erimi.agency/contact">
                <p style={{ color: '#ccc', fontWeight: '600'}}>Contact</p>
              </a>
            </Button>
          </Navbar.Item>
        </Navbar.Content>
      </Navbar>
    </Layout>
  )
}