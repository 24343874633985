import { Grid, Card, Text } from "@nextui-org/react";


import emailw from './img/emailw.png'
import linw from './img/insw.png'
import instaw from './img/linw.png'
import phonew from './img/phonew.png'



function ContactsContainer() {
    
    return (
        <div id="contacts">
                                      <div style={{  height: '75vh', borderRadius: '30px', width: '90%', marginLeft: '10%', marginTop: '30px'}}>
                                  <div>
                                      <div style={{ marginLeft: '10%'}}>
                                      <div style={{ display: 'flex' }}>
                                          <img style={{ marginTop: '10px',width: '30px', height: '30px', marginTop: '8px'}} src={emailw} />
                                          <a href="mailto:info@erimi.agency" target="_blank" style={{ color: '#fff', marginLeft: '10px', fontWeight: '800'}}>
                                              <p style={{ fontWeight: '600', fontSize: '25px'}}>info@erimi.agency</p>
                                          </a>
                                      </div>
                                      <div style={{ marginTop: '10px',display: 'flex' }}>
                                          <img style={{ width: '30px', height: '30px', marginTop: '8px'}} src={phonew} />
                                          <a style={{ color: '#fff', marginLeft: '10px', fontWeight: '800'}}>
                                              <p style={{ fontWeight: '600', fontSize: '25px'}}>+34 601 563 611</p>
                                          </a>
                                      </div>
                                      <div style={{ marginTop: '10px',display: 'flex' }}>
                                          <img style={{ width: '30px', height: '30px', marginTop: '8px'}} src={linw} />
                                          <a href="https://www.linkedin.com/company/erimi" target="_blank" style={{ color: '#fff', marginLeft: '10px', fontWeight: '800'}}>
                                              <p style={{ fontWeight: '600', fontSize: '25px'}}>Erimi Agency</p>
                                          </a>
                                      </div>
                                      <div style={{ marginTop: '10px',display: 'flex' }}>
                                          <img style={{ width: '30px', height: '30px', marginTop: '8px'}} src={instaw} />
                                          <a href="https://www.instagram.com/erimiagency/" target="_blank" style={{ color: '#fff', marginLeft: '10px', fontWeight: '800'}}>
                                              <p style={{ fontWeight: '600', fontSize: '25px'}}>@erimi.agency</p>
                                          </a>
                                      </div>
                                      </div>
                                      <div style={{ width: '80%', marginLeft: '5%',marginTop: '30px', padding: '30px', borderRadius: '30px'}}>
                                          <Text h5 size={20} css={{ textGradient: "35deg, #fff 100%, #882CFE 90%" }} weight="bold">
                                          Get in touch with us the way you prefer.
                                          </Text>
                                          <Text h5 size={20} css={{ textGradient: "35deg, #fff 100%, #882CFE 90%" }} weight="bold">
                                          Our team is always ready to answer any doubts you may have.
                                          </Text>
                                      </div>
                                  </div>
                              </div>
        </div>
      );
  }
  
  export default ContactsContainer