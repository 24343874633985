import { Text, Grid, Card, Button } from "@nextui-org/react";
import Naves from './Nav/Nav-es';
import Nav from './Nav/Nav';
import Footer from './Footer';


function Software() {
    return (
        <div>
            <Nav />
            <div style={{ background: '#000', minHeight: '90vh', paddingTop: '80px', padding: '10% 15%' }}>
                        <div id="agency-card" style={{  justifyContent: 'center' }}>
                            <Text h3 size={50} css={{ textGradient: "35deg, #882CFE 100%, #882CFE 90%" }} weight="bold">
                                Tailor-made solutions
                            </Text>
                            <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                                At Erimi, we specialize in the transformation of businesses into the exciting world of Web3. Our expertise enables us to help companies adapt to this new digital era, regardless of their industry.
                            </Text>
                            <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                            Our solutions enable you to harness all the advantages that Web3 offers. From implementing blockchain technologies to creating decentralized applications, we are ready to drive your success in this innovative environment.
                            </Text>
                        </div>
                        
                <div id="aboutus_valores">
                    <Text h3 size={30} css={{ textGradient: "35deg, #882CFE, 100%, #882CFE 90%", marginTop: '100px'}} weight="bold">
                        Benefits of using Web3
                    </Text>
                    <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                    In the business world, technology's pace waits for no one. Do you want to maintain a competitive edge and stay one step ahead of your competitors?
                    </Text>
                    <Grid.Container gap={2} justify="center" style={{ display: 'flex' , marginTop: '30px'}}>
                        <Grid xs={12} md={6} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #882CFE', background: "black" }}>
                                <Card.Header>
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px', color: '#882CFE' }}>Decentralization and transparency.</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Data and transactions are stored in a distributed and transparent network. This ensures trust among the involved parties and eliminates the need for intermediaries.</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #882CFE', background: "black" }}>
                                <Card.Header>
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px', color: '#882CFE' }}>Security.</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Blockchain in Web3 uses advanced cryptography to protect data and prevent unauthorized modifications. This provides an additional layer of security.</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #882CFE', background: "black" }}>
                                <Card.Header>
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px' ,color: '#882CFE' }}>New Income</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Take advantage of innovative business models, such as non-fungible tokens (NFTs) and utility tokens, which open new ways to generate revenue and monetize your products or services.</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #882CFE', background: "black" }}>
                                <Card.Header>
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px', color: '#882CFE' }}>Global Community</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Web3 is breaking geographical barriers and enabling global participation. By creating a solution in this environment, your business can attract users and customers from all around the world.</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Grid.Container>
                    
                </div>
                <div id="agency-card" style={{  justifyContent: 'center', marginTop: '100px' }}>
                            <Text h3 size={30} css={{ textGradient: "35deg, #882CFE 100%, #882CFE 90%" }} weight="bold">
                                Erimi Web3
                            </Text>
                            <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                            By choosing our Web3 solutions, you ensure that your business is not only up to the current technological challenges but also prepared to lead the next wave of innovation. The decentralized economy is the path to the future, and we will guide you towards success on this exciting journey.
                            </Text>
                            <Grid.Container gap={2} justify="center" style={{ display: 'flex' , marginTop: '30px'}}>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>DApps</Text>
                                        </Card.Body>
                                     </Card>
                                </Grid>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>NFT Marketplace</Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>Crypto Integration</Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>Wallet Integration</Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>DeFi</Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>Smart Contracts</Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                            </Grid.Container>
                        </div>
                        <div style={{ marginTop: '10%', borderRadius: '30px' }}>
                    <div id="agency-card">
                        <div id="aboutus-adjust" style={{ padding: '5%', textAlign: 'center' }}>
                            <Text h3 size={40} css={{ textGradient: "35deg, #f2f2f2, 100%, #882CFE 90%" }} weight="bold">
                            Are you ready to take your business to the next level?
                            </Text>
                            <Text h3 css={{ color: '#cccccc' }}>
                            Get in touch with us, and we will provide you with information without any obligation. Our team of experts will be delighted to advise you on how our Web3 solutions can drive your business's success.
                            </Text>
                            <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <a href="https://www.erimi.agency/contact">
                                <Button size="lg"  style={{ backgroundColor: '#882CFE' , color: '#ccc', fontFamily: 'Nunito, sans-serif', fontWeight: 800, marginTop: '30px', marginBottom: '30px'}}>
                                        <p style={{ color: 'black', fontWeight: '600'}}>Contact</p>
                                </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
            <Footer />
        </div>
    );
}

export default Software;