import { Text, Grid, Card, Button } from "@nextui-org/react";
import Nav from './Nav/Nav';
import Footer from './Footer';

function Agency() {

    return (
        <div>
            <Nav />
            <div style={{ background: '#000', minHeight: '90vh', paddingTop: '80px', padding: '10% 15%' }}>
                        <div id="agency-card" style={{  justifyContent: 'center' }}>
                            <Text h3 size={50} css={{ textGradient: "35deg, #FCA6BF 100%, #FCA6BF 90%" }} weight="bold">
                                Services to achieve your goals
                            </Text>
                            <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                                Our goal is to provide you with creative and innovative solutions to drive your business towards success. Whether you are looking to create a stunning landing page or develop a complete ecommerce platform, our passionate team is ready to accompany you every step of the way.
                            </Text>
                            <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                            Our philosophy is based on innovation and creativity, always seeking unique solutions for each challenge. We take pride in being pioneers in our industry and making a difference in every project we undertake
                            </Text>
                            
                        </div>
                <div id="aboutus_valores">
                    <Text h3 size={30} css={{ textGradient: "35deg, #FCA6BF, 100%, #FCA6BF 90%", marginTop: '100px'}} weight="bold">
                    What are the benefits of digitalizing your business?
                    </Text>
                    <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                    We live in a constantly evolving digital age, where an online presence is essential for the growth and survival of any business. Here are some compelling reasons to digitize your business:
                    </Text>
                    <Grid.Container gap={2} justify="center" style={{ display: 'flex' , marginTop: '30px'}}>
                        <Grid xs={12} md={6} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #FCA6BF', background: "black" }}>
                                <Card.Header>
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px', color: '#FCA6BF' }}>Expanding global presence</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>With an online presence, your business can reach audiences worldwide, opening opportunities to expand your horizons and increase your potential sales</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #FCA6BF', background: "black" }}>
                                <Card.Header>
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px', color: '#FCA6BF' }}>Increase of sales</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>A well-designed and functional ecommerce platform allows you to sell your products or services 24/7, reaching potential customers even when your physical store is closed</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #FCA6BF', background: "black" }}>
                                <Card.Header>
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px' ,color: '#FCA6BF' }}>Data analysis and monitoring</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>With digital tools, you can gather valuable data about your customers' behavior, enabling you to make more informed decisions to enhance your marketing and sales strategies</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} style={{ justifyContent: 'center' }}>
                            <Card css={{ p: "$6", mw: "400px" }} style={{ border: '3px solid #FCA6BF', background: "black" }}>
                                <Card.Header>
                                    <Text h4 css={{ lineHeight: "$xs" }} style={{ color: '#ccc', marginLeft: '10px', marginTop: '10px', color: '#FCA6BF' }}>Competitiveness</Text>
                                </Card.Header>
                                <Card.Body css={{ py: "$2" }}>
                                    <Text style={{ color: '#ccc' }}>Digitalization helps you stay on par with the competition and even surpass it. Companies that do not adopt a digital strategy run the risk of falling behind and missing out on opportunities</Text>
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Grid.Container>
                    
                </div>
                <div id="agency-card" style={{  justifyContent: 'center', marginTop: '100px' }}>
                            <Text h3 size={30} css={{ textGradient: "35deg, #FCA6BF 100%, #FCA6BF 90%" }} weight="bold">
                                Erimi Services
                            </Text>
                            <Text p css={{ color: '#cccccc', fontSize: '19px' }}>
                            Our agency offers a variety of services to boost your online business. Some of our specialties include designing impactful landing pages, developing comprehensive ecommerce solutions, digital marketing strategies, corporate identity design, and mobile app development. We are dedicated to creating personalized solutions that enhance your brand visibility and drive increased web traffic. Additionally, we work on strengthening your corporate identity to effectively convey your business values and essence. Our team also develops innovative and functional mobile applications to enhance interaction with your audience. Contact us to discover how we can assist you in achieving your digital goals.
                            </Text>
                            <Grid.Container gap={2} justify="center" style={{ display: 'flex' , marginTop: '30px'}}>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>Landing Pages</Text>
                                        </Card.Body>
                                     </Card>
                                </Grid>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>E-commerce</Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                                <Grid xs={12} md={4} style={{ justifyContent: 'center' }}>
                                    <Card isHoverable variant="bordered" css={{ mw: "400px", background: '#ccc' }}>
                                        <Card.Body>
                                            <Text style={{ color: '#333', fontWeight: '600', fontSize: '20px', textAlign: 'center'}}>Web Design</Text>
                                        </Card.Body>
                                    </Card>
                                </Grid>
                            </Grid.Container>
                        </div>
                        <div style={{ marginTop: '10%', borderRadius: '30px' }}>
                    <div id="agency-card">
                        <div id="aboutus-adjust" style={{ padding: '5%', textAlign: 'center' }}>
                            <Text h3 size={40} css={{ textGradient: "35deg, #f2f2f2, 100%, #FCA6BF 90%" }} weight="bold">
                            Are you ready to take your business to the next level?
                            </Text>
                            <Text h3 css={{ color: '#cccccc' }}>
                            At Erimi, our goal is to provide you with creative and innovative solutions that drive the success of your company. Get in touch with us, and we'll be happy to provide you with information without any obligation.
                            </Text>
                            <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <a href="https://www.erimi.agency/contact">
                                <Button size="lg"  style={{ backgroundColor: '#FCA6BF' , color: '#ccc', fontFamily: 'Nunito, sans-serif', fontWeight: 800, marginTop: '30px', marginBottom: '30px'}}> 
                                        <p style={{ color: 'black', fontWeight: '600'}}>Contact</p>   
                                </Button>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
            <Footer />
        </div>
    );
}

export default Agency;