import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function CalendlyContainer() {
  useEffect(() => {
    // This will load the Calendly widget script dynamically
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="calendly">
      <div style={{ marginLeft: '10%' }}>
        <div style={{ background: '#fff', padding: '30px', borderRadius: '30px', border: '4px solid #882CFE' }}>
          <Helmet id="helmet" style={{ width: '70%' }}>
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
          </Helmet>
          <div className="calendly-inline-widget" data-url="https://calendly.com/erimiagency?hide_gdpr_banner=1&text_color=000000&primary_color=882cfe" style={{ minWidth: '100px', height: '65vh' }}></div>
        </div>
      </div>
    </div>
  );
}

export default CalendlyContainer;
