import logo from './logo.svg';
import './App.css';
import Nav from './Nav/Nav'
import Hero from './Hero/Hero'
import Features from './Features'
import About from './About'
import Footer from './Footer'

function App() {
  return (
    <div className="App">
      <Nav />
      <Hero />
      <Features />
      <About />
      <Footer />
    </div>
  );
}

export default App;
