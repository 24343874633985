import { Text,Button, Grid, Card, Row, Col } from "@nextui-org/react";


import i_create from './img/icreate.png'
import i_remote from './img/iremote.png'
import i_innovation from './img/iinnovate.png'
import i_perfect from './img/iperfect.png'


function About() {
    return(
        <div style={{ background: '#000000', paddingTop: '120px', paddingBottom: '100px'}}>
            <div >
            <Text
        h1
        size={80}
        css={{
          textGradient: "35deg, #882CFE  25%, #92D5A4 60%",
        }}
        weight="bold"
      >
        About us
      </Text>
        </div>
        <div>


        <div style={{color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

            <div>

            <Grid.Container gap={2} justify="center">
                <Grid xs={12} md={6}>
                    <div style={{ textAlign: 'left', margin: '0px 50px'}}>
                    <div id="adn_con" style={{ marginTop: '40px', background: '#92D5A4', color: 'black', padding: '30px', borderRadius: '15px'}}>
                    <Text id="con_block"h3 size={30} css={{ marginTop: '0px'}} weight="bold">
                            Fully scalable
                        </Text>
                        <Text p css={{ fontWeight: '600' }}>
                            The capacity to adapt and scale on the spot is in our DNA, that's why we have developed a business model that is profitable in smaller projects, as well as in large ones
                        </Text>
                    </div>
                        <div id="adn_con" style={{ padding: '30px'}}>
                        <Text h3 size={30} css={{ marginTop: '30px', textGradient: "35deg, #ebe8e8 100%, #882CFE 90%"}} weight="bold">
                            Efficient processes
                        </Text>
                        <Text p css={{ color: '#cccccc', fontWeight: '600' }}>
                            We are a dynamic team with a new way of doing things. Fully scalable, we are a remote first company that values work conditions and empployee work-life balance.
                        </Text>
                        </div>
                    </div>                
                </Grid>
                <Grid xs={12} md={6}>
                    <div style={{ padding: '0px 30px', marginLeft: '15%', marginBottom: '50px'}}>
                    <Card isHoverable variant="bordered" css={{ mw: "400px" }} style={{ backgroundColor: '#121212', height: '80px', margin: '20px 0px'}}>
                    <Card.Body>
                        <div style={{ display: 'flex' }}>
                            <img style={{ width: '35px', margin: '0px 20px 0px 10px' }} src={i_create} />
                            <Text style={{ marginTop: '5px', marginRight: '20px', color: '#ccc', fontWeight: '800'}}>Creativity</Text>
                        
                        </div>
                    </Card.Body>
                </Card>
                <Card isHoverable variant="bordered" css={{ mw: "400px" }} style={{ backgroundColor: '#121212', height: '80px', margin: '20px 0px'}}>
                    <Card.Body>
                        <div style={{ display: 'flex' }}>
                            <img style={{ width: '35px', margin: '0px 20px 0px 10px' }} src={i_remote} />
                            <Text style={{ marginTop: '5px', marginRight: '20px', color: '#ccc', fontWeight: '800'}}>Remote first</Text>
                        </div>
                    </Card.Body>
                </Card>  
                <Card isHoverable variant="bordered" css={{ mw: "400px" }} style={{ backgroundColor: '#121212', height: '80px', margin: '20px 0px'}}>
                    <Card.Body>
                        <div style={{ display: 'flex' }}>
                            <img style={{ width: '35px', margin: '0px 20px 0px 10px' }} src={i_innovation} />
                            <Text style={{ marginTop: '5px', marginRight: '20px', color: '#ccc', fontWeight: '800'}}>Innovation</Text>
                        </div>
                    </Card.Body>
                </Card>   
                <div style={{ background: '#121212', width: '100%' , padding: '10px 20px', borderRadius: '15px'}}>
                <Text id="padding_responsive" h3 size={25} css={{ color: '#ccc', }} weight="bold">
                    Certified by the industry
                </Text>
                <div style={{ display: 'flex', justifyContent: 'center', background: '#fff', width: '80%', marginLeft: '10%', marginTop: '10px', marginBottom: '10px', padding: '10px 10px 0px 10px', borderRadius: '10px'}}>
                    <div>
                        <a href="https://topitcompanies.co/categories/blockchain-developers/country/spain" target="_blank"> 
                        <img src="https://topitcompanies.co/badges/blockchain-developers.png" alt="blockchain app developers" title="Blockchain App Developers" style={{height: '100px', width: '100px'}} />
                        </a>
                    </div>
                    <div>
                        <a href="https://topitcompanies.co/categories/nft-marketplace-developers/country/spain" target="_blank"> <img src="https://topitcompanies.co/badges/nft-marketplace-developers.png" alt="nft marketplace developers" title="NFT Marketplace Developers" style={{height: '100px', width: '100px'}} />
                        </a>
                    </div>
                </div>
                <div>
                    <div style={{ padding: '20px 20px'}}>
                        <img src="https://www.designrush.com/topbest/images/svg/designrush-new-logo.svg" />
                        <a style={{ color: '#121212'}} href="https://www.designrush.com/agency/profile/erimi">
                            <button style={{ fontSize: '15px', fontWeight: '600', background: '#92D5A4', border: 'none', marginTop: '20px', padding: '10px 15px',  borderRadius: '15px'}}>Review Erimi Agency on DesignRush</button>
                        </a>
                    </div>
                </div>
                </div>       
                    </div>
                </Grid>
             </Grid.Container>
                

             <div style={{ padding: '80px 0px', margin: '0% 5%', borderRadius: '30px'}}>
            <Text h3 size={30} style={{ color: "#ccc"}} weight="bold">
                    Get in touch with us, we are looking forward to hearing about your new projects
                </Text>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <a href="https://www.erimi.agency/agency">
                    <Button style={{ background: '#92D5A4',border: '1px solid #000', color: '#000000', fontWeight: '800'}}>Read more about Erimi</Button>
                    </a>
                </div> 
            </div>
            </div>
            </div>




        </div>
        </div>
    )
}



export default About