import insta from './img/ininsta.png'
import linked from './img/inlin.png'
import mail from './img/inmail.png'

import { Text, Button, Grid, Card, Row, Col } from "@nextui-org/react";




function Footer() {
    return(
        <div style={{ background: '#000'}}>
            <div style={{ borderTop: '0.5px solid #92D5A4', margin: '0px 5%'}}>
                <div style={{ display: 'flex' }}>
                   <div style={{ justifyContent: 'center', margin: '20px 0px' }}>
                        <a href="https://www.instagram.com/erimiagency/" target="_blank" style={{ margin: '0px 10px' }}>
                            <img style={{ borderRadius: '100%',width: '40px', background: '#92D5A4', padding: '6px'}} src={insta} />
                        </a>
                        <a href="https://www.linkedin.com/company/erimi/" target="_blank" style={{ margin: '0px 10px' }}>
                            <img style={{ borderRadius: '100%',width: '40px', background: '#92D5A4', padding: '6px'}} src={linked} />
                        </a>
                        <a href="mailto:info@erimi.agency" target="_blank" style={{ margin: '0px 10px' }}>
                            <img style={{ borderRadius: '100%',width: '40px', background: '#92D5A4', padding: '6px'}} src={mail} />
                        </a>
                   </div>
                   <div style={{ position: 'absolute', right: '5%', marginTop: '20px'}}>
                        <Text style={{ fontSize: '1rem' }} css={{ color: '#cccccc' }}>
                            <b>Erimi</b>Agency
                        </Text>
                        <Text style={{ fontSize: '0.8rem' }} css={{ }}>
                            <a href="https://www.erimi.agency/privacy" style={{color: '#cccccc' }}>Privacy Policy</a>
                        </Text>
                        
                   </div>
                </div>
            </div>
        </div>
    )
}

export default Footer