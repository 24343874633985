import React from 'react';
import logo from './img/logo.png'

const NotFound = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    color: '#f3f3f3',
    height: '100vh' // this makes sure it covers the entire height of the viewport
  }}>
    <div style={{
      textAlign: 'center' // this centers the text and content inside the inner div
    }}>
      <img src={logo} alt="Logo" style={{ width: '300px'}} /> {/* added alt for accessibility */}
      <h1 style={{ marginTop: '40px'}}>You got lost?</h1>
      <p>The page you're looking for doesn't exist.</p>
      <button style={{ marginTop: '50px', backgroundColor: '#FCA6BF', padding: '5px 20px', color: '#000', border: 'none', borderRadius: '30px'}}>
        <p style={{ fontWeight: '600'}}>Go Home</p>
      </button>
    </div>
  </div>
);

export default NotFound;
