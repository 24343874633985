import { Text,Button } from "@nextui-org/react";
import '../App.css'

import microsoft from '../img/microsoft.png'
import google from '../img/google.png'
import websummit from '../img/web.png'
import shopify from '../img/shopify.png'



export default function Hero() {
  return (
    <div style={{backgroundColor: 'black', paddingTop: '25vh' }}>
      <Text
        h1
        size={100}
        id="hero_headline"
        css={{
          textGradient: "35deg, #882CFE  25%, #92D5A4 60%",
        }}
        weight="bold"
      >
        Agency. Web3. Products
      </Text>
      <Text
        h3
        css={{
            color: '#cccccc', maxWidth: '70vw', marginLeft: '15vw'
          }}
      >
        We develop tools to solve industry inefficiencies using a mix of innovation and creativity in a fully adaptable workspace
      </Text>
      <div style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
      <div style={{ background: '#882CFE', display: 'flex', width: '300px', marginTop: '80px', padding: '10px 20px', borderRadius: '20px'}}>
          <p style={{ textAlign: 'left',color: '#fffE', width: '60%', fontWeight: '800', fontSize: '16px'}}>Schedule a call</p>
          <a style={{ width: '40%' }} target="_blank" href="https://calendly.com/erimiagency/30min?embed_domain=www.erimi.agency&embed_type=Inline&hide_gdpr_banner=1&text_color=000000&primary_color=882cfe&back=1&month=2023-08">
            <button style={{ background: '#fff', border: 'none', color: '#882CFE', borderRadius: '10px', fontWeight: '600'}} >CALENDAR</button>
          </a>
        </div>
      </div>
      <div style={{ background: '#000', marginTop: '100px'}}>
        <Text id="padding_responsive" h3 size={50} css={{ textGradient: "35deg, #882CFE 100%, #882CFE 90%"}} weight="bold">
          Partners
        </Text>
        <div id="partners" style={{ display: 'flex', width: '80%', marginLeft: '10%', marginTop: '50px'}}>
          <div id="partner_logo" style={{ width: '25%', padding: '15px' }}>
            <img style={{ width: '50%' }} src={google} />
          </div>
          <div id="partner_logo" style={{ width: '25%', padding: '15px', marginTop: '-20px' }}>
            <img style={{ width: '50%' }} src={websummit} />
          </div>
          <div id="partner_logo" style={{ width: '25%', padding: '15px', marginTop: '10px' }}>
            <img style={{ width: '50%' }} src={microsoft} />
          </div>
          <div id="partner_logo" style={{ width: '25%', padding: '15px' }}>
            <img style={{ width: '50%', marginTop: '5%' }} src={shopify} />
          </div>
        </div>
      </div>
    </div>
  );
}
