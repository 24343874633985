import { Grid, Card, Text } from "@nextui-org/react";
import { Helmet } from 'react-helmet';

import './App.css'
import Footer from './Footer'

import Nav from './Nav/Nav'

import Whatsapp from './Whatsapp'
import Calendly from './Calendly'
import ContactDetails from './ContactDetails'


function Contact() {
    return(
        <div>
            <Nav />
            <div id="contact_con" style={{ minHeight: '95vh', background: '#000', paddingTop: '60px'}}>
                <Grid.Container gap={2} justify="center">
                    <Grid xs={12} md={4}>
                        <Whatsapp />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Calendly />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <ContactDetails />
                    </Grid>
                </Grid.Container>
            </div>
            <Footer />
        </div>
    )
}

export default Contact