import {useEffect} from 'react'

import wqr from './img/wqr.jpeg'
import wa from './img/wa.png'
import link from './img/link.png'
import phone from  './img/phone.png'


function WhatsAppContainer() {

        return (
            <div id="whatsapp">
                                      <div style={{ background: '#fff ', padding: '20px', borderRadius: '30px',marginLeft: '10%', border: '4px solid #92D5A4'}}>
                                      <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px'}}>
                                          <img style={{ width: '50px' }} src={wa} />
                                      </div>
                                      <div style={{ display: 'flex', justifyContent: 'center'}}>
                                          <img style={{ width: '200px', borderRadius: '20px' }} src={wqr} />
                                      </div>
                                      <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                                          <a style={{ marginBottom: '30px',marginTop: '10px', display: 'flex'}} target="_blank" href="https://api.whatsapp.com/send?phone=+34666%C2%A0694%C2%A0099&text=Hello%21+I+am+contacting+you+from+Erimi%27s+website.%0A%0AI+would+like+to+obtain+more+information+about+your+services+and+how+we+can+incorporate+them+into+our+business.%0A%0ABest+regards%21">
                                              <img style={{ width: '20px', marginRight: '5px', marginTop: '3px' }} src={phone} />
                                              <p style={{ fontWeight: '600', color: '#92D5A4'}}>+34 601 563 611</p>
                                          </a>
                                      </div>
                                  </div>
            </div>
          );
  }

  export default WhatsAppContainer
  