import Nav from './Nav/Nav'
import Footer from './Footer'
import { Grid, Card, Text, Button } from "@nextui-org/react";

import wrklogo from './img/w-grad.jpeg'



function New(){

    return(
        <div style={{ background: '#000' }}>
            <Nav />
        <div style={{ marginTop: '80px', minHeight: '80vh'}}>
        <Grid.Container gap={2} justify="center">
                <Grid xs={12} md={4} style={{ position: 'flex', justifyContent: 'center'}}>
                    <img style={{ borderRadius: '30px', width: '300px', height: '300px'}} src={wrklogo} />
                </Grid>
                <Grid xs={12} md={4} style={{padding: '5vw' , margin: '5vh 0vh'}}>
                <div style={{ display: 'flex', justifyContent: 'left', marginTop: '-10%'}}>
                    <div id="list_frame" style={{ background: '#FFF', borderRadius: '30px', padding: '40px 20px 40px 20px'}}>
                    <Text h3 size={30} css={{ color: '#882CFE', textAlign:'center' }}>
                        Join the waitlist to try it for free
                    </Text>
                    <iframe
                    width="540"
                    height="345"
                    src="https://a137303e.sibforms.com/serve/MUIFADa7BUs0sGU1WtoDKK3Qp2TIZKy02Nv6bt8-nz1zQhSgFV4VkkLiVhJtheoft_nUAk9l-LIM1-0usVNXheWf9a5dWR7z16cxoPSGUZZGwcP8gNDvgZKNlCZU9BGQyqvU0YfNE_lx-h0bOzi3g2erJxm3zIv0ACO_21DHEjfDO-YGliC5xLfZQA5Rs2p-JoL-8hVNGsNYeazv"
                    frameBorder="0"
                    scrolling="auto"
                    allowFullScreen
                    style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}
                />
                    </div>
               </div>
            </Grid>
            <Grid xs={12} md={4} >
                <div id="text_wrk">
                <Text h3 size={50} css={{ textGradient: "35deg, #FCA6BF 100%, #882CFE 90%"}} weight="bold">
                    WRKSYNC
                </Text>
                <Text id="text_wrk" h3 css={{ color: '#cccccc' }}>
                Your new work companion: Automate your daily tasks and save time and money with WRKSYNC!
                </Text>
                <Text id="text_wrk" h3 css={{ color: '#cccccc' }}>
                By using your work data and Artificial Intelligence, it automatically generates your invoices, organizes your work hours, and provides your clients with real-time estimates using Time Estimation. This tool analyzes your strengths and, with a brief description of the task at hand, gives you an approximate estimation of the time needed to complete it.
                </Text>
                </div>
            </Grid>
            
        </Grid.Container>
        <div style={{textAlign: 'center'}}>
        </div>
        </div>
        <Footer />
    </div>
    )
}

export default New